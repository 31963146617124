module.exports ={
  "propertyService": "https://qi7q3db1f4.execute-api.us-east-1.amazonaws.com/matt",
  "domusoApiService": "https://e2bsirfi3f.execute-api.us-east-1.amazonaws.com/matt",
  "appService": "https://domusograilsweb-matt.devmuso.com",
  "reservationService": "https://uenvux1fsg.execute-api.us-east-1.amazonaws.com/matt",
  "userPoolId": "us-east-1_kPRU6HBdW",
  "authService": "https://y7zrmq4m2h.execute-api.us-east-1.amazonaws.com/matt",
  "userPoolWebClientId": "22uckdipuae3ktona5v6ttgv9s",
  "emailLinkBase": "https://domusograilsweb-matt.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-dev@devmuso.com",
  "residentWebUI": "https://resident-matt.devmuso.com",
  "maintenanceUrl": "https://y7zrmq4m2h.execute-api.us-east-1.amazonaws.com/matt/maintenance",
  "env": "matt"
}